import axios from "axios";

export const findPayment = async (paymentId, user) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payments/users/${user._id}/${paymentId}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const findAllPayments = async (user, pageNumber = 1) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payments/users/${user._id}?page=${pageNumber}&sortBy=due_date&sortOrder=desc`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const findAllPendingPayments = async (user, pageNumber = 1) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payments/users/${user._id}?page=${pageNumber}&sortBy=due_date&sortOrder=desc&status=pending`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const adminFindUserPayments = async (id, token, pageNumber = 1) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payments/users/${id}?page=${pageNumber}&sortBy=due_date&sortOrder=desc`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const createPayment = async (payment, userId, token) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/payments/users/${userId}`,
      payment,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const adminGetAllPayments = async (
  token,
  pageNumber = 1,
  filters = ""
) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payments?page=${pageNumber}${filters}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

