// checkoutActions.js
import { getAllBillingDetails } from "../helpers/billingDetailsApi";
import { getOnePayment } from "../helpers/usersApi";

export const fetchPayment = async (dispatch, userId, token, id) => {
  try {
    dispatch({ type: "FETCH_PAYMENT_START" });
    const payment = await getOnePayment(userId, token, id);
    dispatch({ type: "FETCH_PAYMENT_SUCCESS", payload: payment });
  } catch (error) {
    dispatch({ type: "FETCH_PAYMENT_ERROR", payload: error.message });
  }
};

export const fetchBillingDetails = async (dispatch, user) => {
  try {
    dispatch({ type: "FETCH_BILLING_DETAILS_START" });
    const billingDetailsList = await getAllBillingDetails(user);

    dispatch({
      type: "FETCH_BILLING_DETAILS_SUCCESS",
      payload: billingDetailsList.data,
    });
  } catch (error) {
    // console.error(error.message);
    dispatch({ type: "FETCH_BILLING_DETAILS_ERROR", payload: error.message });
  }
};

export const setSelectedBillingDetails = (dispatch, billingDetails) => {
  dispatch({ type: "SET_SELECTED_BILLING_DETAILS", payload: billingDetails });
};

export const addOneBillingDetail = (dispatch, billingDetail) => {
  dispatch({ type: "ADD_ONE_BILLING_DETAIL", payload: billingDetail });
};
