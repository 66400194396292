import React from "react";
import { MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";
import { formatDateString } from "../../helpers/time";

const CheckoutDetails = ({ payment }) => {
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle className="font-semi-bold title l-title">
          Invoice
        </MDBCardTitle>
        <div className="body-text">
          <div className="d-flex bg-light bottom-sub-box mb-3">
            <div className="me-auto p-2">Service</div>
            <div className="p-2">{payment.title} </div>
          </div>
          <div className="d-flex bg-light bottom-sub-box mb-3">
            <div className="me-auto p-2">Description</div>
            <div className="p-2">{payment.description}</div>
          </div>
          <div className="d-flex bg-light bottom-sub-box mb-3">
            <div className="me-auto p-2">Payment Due</div>
            <div className="p-2">{formatDateString(payment.due_date)} </div>
          </div>
          <div className="d-flex bg-light bottom-sub-box mb-3">
            <div className="me-auto p-2">Type</div>
            <div className="p-2">
              {payment.isRecurring ? "Recurring" : "One Time"}{" "}
            </div>
          </div>
          <div className="d-flex bg-light bottom-sub-box mb-3">
            <div className="me-auto p-2">Amount to pay</div>
            <div className="p-2">$ {payment.amount} </div>
          </div>
        </div>
        {/* ... */}
      </MDBCardBody>
    </MDBCard>
  );
};

export default CheckoutDetails;
