import { MDBCardImage, MDBCardText } from "mdb-react-ui-kit";
import invoicePageImg from "../../assets/invoice-page.png";
import invoiceImg from "../../assets/invoice.png";
import React from "react";

const FindPaymentGuide = () => {
  return (
    <>
      <MDBCardText className="fs-4">
        Follow these steps to find past invoices.
      </MDBCardText>
      <div>
        <MDBCardImage src={invoicePageImg} className="help-img" />
        <MDBCardText className="mt-3 fs-5">
          Step 1: Log in to your account and navigate to the 'Invoices' section
          by clicking on "Invoices" in the sidebar on the left, on this page you
          can see all invoices both paid and pending. Click on the invoice you
          want details for.
        </MDBCardText>
      </div>
      <div className="mt-3">
        <MDBCardImage src={invoiceImg} className="help-img" />
        <MDBCardText className="mt-3 fs-5">
          Step 2: On the page for the details of your invoice, you can see the
          title, amount, description and invoice number for the invoice in
          particular. From here you can print or pay your invoice.
        </MDBCardText>
      </div>
    </>
  );
};

export default FindPaymentGuide;
