import React, { useState } from "react";
import "../HomePageComponents/HomePage.css";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import { forgotPassword } from "../../helpers/usersApi";
import { Link } from "react-router-dom";
import SmallLoadingSpinner from "../LoadingSpinners/SmallLoadingSpinner";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isloading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    await forgotPassword(email);
    setIsLoading(false);
  };

  return (
    <div className="container-fluid ps-md-0">
      <div className="row g-0">
        <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                  <h3 className="login-heading mb-4">
                    Enter The Email Address For Your Account
                  </h3>
                  <p>A link will be emailed to you to reset your password</p>
                  {/* Sign In Form */}
                  <form onSubmit={onSubmit}>
                    <MDBInput
                      className="mb-4"
                      type="email"
                      id="form1Example1"
                      label="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <MDBBtn type="submit" block>
                      {isloading ? <SmallLoadingSpinner /> : "Send"}
                    </MDBBtn>
                  </form>
                  <div className="mt-5">
                    <Link to="/">Go Back</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
