import "./LoadingSpinner.css";

const SmallLoadingSpinner = () => (
  <div
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
  >
    <div
      style={{
        border: "2px solid #f3f3f3",
        borderTop: "2px solid #3b71ca",
        borderRadius: "50%",
        width: "15px",
        height: "15px",
        animation: "spin 1s linear infinite",
      }}
    ></div>
  </div>
);

export default SmallLoadingSpinner;
