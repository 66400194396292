import "./App.css";

import React, { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { ToastContainer } from "react-toastify";
import { UserContext } from "./context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "./AppRouter";
import HomePage from "./components/HomePageComponents/HomePage";

import Layout from "./Layout";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ForgotPassword/ResetPassword";

function App() {
  const storedUser = localStorage.getItem("orbtronics-user");
  let parsedUser = null;
  if (storedUser) {
    parsedUser = JSON.parse(storedUser); // Parse the user string back into an object
  }
  const [user, setUser] = useState(parsedUser);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:id/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/*"
            element={
              <Layout>
                <AppRouter />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
