import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol
} from "mdb-react-ui-kit";
import React from "react";
import { formatDateString } from "../../helpers/time";

const OverduePayments = ({ pendingPayments }) => {
  return (
    <>
      <h1>Total Overdue Payments</h1>

      <MDBCol md="12">
        <MDBCard>
          <MDBCardBody>
            <Table aria-label="Overdue Payments">
              <TableHeader>
                <TableColumn>ID</TableColumn>
                <TableColumn>Company</TableColumn>
                <TableColumn>Amount</TableColumn>
                <TableColumn>Due Date</TableColumn>
              </TableHeader>
              <TableBody>
                {pendingPayments.map((payment) => (
                  <TableRow key={payment._id}>
                    <TableCell>{payment._id}</TableCell>
                    <TableCell>{payment.user_id?.company}</TableCell>
                    <TableCell>{payment.amount}</TableCell>
                    <TableCell>{formatDateString(payment.due_date)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>          
            </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </>
  );
};

export default OverduePayments;
