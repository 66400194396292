import React, { useContext, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBBtn,
  MDBAccordion,
  MDBAccordionItem,
  MDBTextArea,
} from "mdb-react-ui-kit";

import "./UsersHelp.css";
import MakePamentGuide from "./MakePamentGuide";
import FindPaymentGuide from "./FindPaymentGuide";
import { errorToast, successfulToast } from "../../helpers/toasties";
import { reportAnIssue } from "../../helpers/usersApi";
import { UserContext } from "../../context/UserContext";

const UsersHelpPage = () => {
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.message === "") {
      return errorToast("Please enter a message");
    }

    const response = await reportAnIssue(user, formData);

    if (response.message === "Issue reported") {
      successfulToast("Issue reported, We will contact you within 24-48 hours");
      setFormData({
        subject: "",
        message: "",
      });
    } else {
      errorToast(
        "Error submitting response, email us directly at info@orbtronicsltd.com"
      );
    }
  };
  return (
    <MDBContainer className="mt-5">
      <MDBRow>
        <MDBCol size="12">
          <MDBCard>
            <MDBCardBody>
              <MDBAccordion>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle="How to make a payment"
                >
                  <MakePamentGuide />
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={2}
                  headerTitle="How to find past invoice details"
                >
                  <FindPaymentGuide />
                </MDBAccordionItem>
              </MDBAccordion>

              {/* Repeat for other steps with their respective screenshots */}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-4">
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Contact Us</MDBCardTitle>
              <MDBCardText>
                For further assistance, please get in touch with us:
              </MDBCardText>
              <MDBCardText>
                Phone: <a href="tel:+17584860469">+1-(758)-486-0469</a>
              </MDBCardText>
              <MDBCardText>
                Email:{" "}
                <a href="mailto:info@orbtronicsltd.com">
                  info@orbtronicsltd.com
                </a>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Report an Issue</MDBCardTitle>
              <form onSubmit={handleSubmit}>
                <div className="grey-text">
                  <MDBInput
                    label="Subject"
                    icon="tag"
                    type="text"
                    name="subject"
                    onChange={handleChange}
                    value={formData.subject}
                  />
                  <MDBTextArea
                    type="textarea"
                    rows="3"
                    label="Your message*"
                    icon="pencil-alt"
                    name="message"
                    onChange={handleChange}
                    value={formData.message}
                  />
                </div>
                <div className="text-center mt-3">
                  <MDBBtn color="primary" type="submit">
                    Submit
                  </MDBBtn>
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default UsersHelpPage;
