import React from "react";
import { MDBCard, MDBCardBody, MDBRow, MDBCol } from "mdb-react-ui-kit";

const BillingDetailsCard = ({ billingDetails, billingDetailsId }) => {
  return (
    <MDBCard
      className={
        billingDetailsId === billingDetails._id
          ? "selected mb-3 details-card"
          : "mb-3 details-card"
      }
      style={{ width: "100%" }}
    >
      <MDBCardBody>
        <MDBRow>
          <MDBCol size="6">
            <p>
              <strong>Address Line 1:</strong> {billingDetails.addressLine1}
            </p>
            <p>
              <strong>Address Line 2:</strong> {billingDetails.addressLine2}
            </p>
          </MDBCol>
          <MDBCol size="6">
            <p>
              <strong>City:</strong> {billingDetails.city}
            </p>
            <p>
              <strong>Postal Code:</strong> {billingDetails.postalCode}
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default BillingDetailsCard;
