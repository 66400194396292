import React, { useContext, useEffect, useReducer, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useParams } from "react-router-dom";
import UserDetailCard from "./UserDetailCard";
import UserPaymentsTable from "./UserPaymentsTable";
import NewPaymentForm from "./NewPaymentForm";
import { createPayment } from "../../helpers/paymentApi";
import { errorToast, successfulToast } from "../../helpers/toasties";
import {
  initialState,
  userDetailsReducer,
} from "../../reducers/userDetailsReducer";
import {
  addUserPayment,
  fetchUserDetails,
  fetchUserPayments,
  setUserDetails,
} from "../../actions/userDetailsActions";
import ErrorComponent from "../ErrorComponent";
import SmallLoadingSpinner from "../LoadingSpinners/SmallLoadingSpinner";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import { MDBBtn } from "mdb-react-ui-kit";

const UserDetailsPage = () => {
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [state, dispatch] = useReducer(userDetailsReducer, initialState);
  const [showNewPaymentForm, setShowNewPaymentForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    fetchUserDetails(dispatch, user, id);
    fetchUserPayments(dispatch, id, user.token, pageNumber);
  }, [id, user.token, user, pageNumber]);

  const { userDetails, payments } = state;

  const handleCreatePayment = () => {
    setShowNewPaymentForm(true);
  };

  const pagesArray = Array.from(Array(payments.data.totalPages).keys());

  const handleCancelNewPayment = () => {
    setShowNewPaymentForm(false);
  };

  const handleSubmitNewPayment = async (paymentData) => {
    // Implement the logic to submit new payment data
    const paymentObj = await createPayment(paymentData, id, user.token);
    if (
      paymentObj.message ===
      `Payment created for ${
        userDetails.data.first_name + " " + userDetails.data.last_name
      }`
    ) {
      addUserPayment(dispatch, paymentObj.data);
      setShowNewPaymentForm(false);
      successfulToast(paymentObj.message);
    } else {
      errorToast("Something went wrong");
    }
  };

  const handleUpdateUser = (updatedUser) => {
    setUserDetails(dispatch, updatedUser);
  };

  if (userDetails.error)
    return <ErrorComponent errorMessage={userDetails.error} />;
  if (payments.error) return <ErrorComponent errorMessage={payments.error} />;

  return (
    <div>
      {userDetails.isLoading ? (
        <SmallLoadingSpinner />
      ) : (
        <>
          <h1>User Details</h1>
          <UserDetailCard
            userInfo={userDetails.data}
            setUserInfo={handleUpdateUser}
          />
        </>
      )}

      <hr className="my-4" />
      {showNewPaymentForm ? (
        <NewPaymentForm
          onSubmit={handleSubmitNewPayment}
          onCancel={handleCancelNewPayment}
        />
      ) : (
        <>
          {payments.isLoading ? (
            <LargeLoadingSpinner />
          ) : (
            <UserPaymentsTable
              payments={payments.data.data}
              handleCreatePayment={handleCreatePayment}
            />
          )}
        </>
      )}

      <div className="pagin-btn-container">
        {pagesArray.map((p) => (
          <MDBBtn
            key={p}
            className={pageNumber === p + 1 ? "pagin-btn active" : "pagin-btn"}
            onClick={() => setPageNumber(p + 1)}
          >
            {p + 1}
          </MDBBtn>
        ))}
      </div>
    </div>
  );
};

export default UserDetailsPage;
