import { MDBCardImage, MDBCardText } from "mdb-react-ui-kit";
import dashboardImg from "../../assets/dashboard-sc.png";
import checkoutImg from "../../assets/checkout-sc.png";
import billingDetailsImg from "../../assets/billing-details-sc.png";
import billingDetailsFormImg from "../../assets/billing-details-form.png";
import invoiceImg from "../../assets/invoice.png";
import React from "react";

const MakePamentGuide = () => {
  return (
    <>
      <MDBCardText className="fs-4">
        Follow these steps to complete your payment.
      </MDBCardText>
      <div>
        <MDBCardImage src={dashboardImg} className="help-img" />
        <MDBCardText className="mt-3 fs-5">
          Step 1: Log in to your account and navigate to the 'Dashboard'
          section, then proceed to click on the 'Latest Invoice' card with the
          invoice details below it.
        </MDBCardText>
      </div>
      <div className="mt-3">
        <MDBCardImage src={checkoutImg} className="help-img" />
        <MDBCardText className="mt-3 fs-5">
          Step 2: On the checkout page, click on the "Add New" button to add
          your billing details.
        </MDBCardText>
      </div>
      <div className="mt-3">
        <MDBCardImage src={billingDetailsFormImg} className="help-img" />
        <MDBCardText className="mt-3 fs-5">
          Step 3: Fill out the form and click on the "Submit Details" button to
          submit the information.
        </MDBCardText>
      </div>
      <div className="mt-3">
        <MDBCardImage src={billingDetailsImg} className="help-img" />
        <MDBCardText className="mt-3 fs-5">
          Step 4: Click on the card that appears up below with your billing
          details to select it. Once selected the background of the card would
          change from white to blue. Then select the "Proceed to Payment" button
          on the right.
        </MDBCardText>
      </div>
      <div className="mt-3">
        <MDBCardImage src={invoiceImg} className="help-img" />
        <MDBCardText className="mt-3 fs-5">
          Step 5: Enter your credit card details in the form that pops up and
          click submit once finished. Once the transaction is complete you will
          be redirected to the invoice details page where you can see the
          details of the transaction and print the invoice.
        </MDBCardText>
      </div>
    </>
  );
};

export default MakePamentGuide;
