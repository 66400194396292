// CreditCardModal.js
import React, { useEffect, useState } from "react";
import "./CreditCardModal.css"; // Make sure to create this CSS file
import {
  checkoutPayment,
  updatePaymentAfterCheckout,
} from "../../helpers/checkoutApi";
import { useNavigate } from "react-router-dom";
import { successfulToast, errorToast } from "../../helpers/toasties";
import { validateCardDetails } from "../../helpers/validateCardData";
import SmallLoadingSpinner from "../LoadingSpinners/SmallLoadingSpinner";
import CreditCardForm from "./CreditCardForm";

const CreditCardModal = ({
  isOpen,
  onClose,
  billingDetails,
  amount,
  user,
  paymentId,
}) => {
  const [iframeContent, setIframeContent] = useState("");
  const [billingId, setBillingId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const extractTransactionId = (eventData) => {
      const regex = /TransactionID- (\S+)/;
      const match = eventData.match(regex);
      const transactionId = match ? match[1] : "unknown";
      return transactionId;
    };

    const handleMessage = async (event) => {
      const backendUrl = process.env.REACT_APP_API_BACK_END_URL;

      if (event.origin === backendUrl) {
        // Ensure the message is from a trusted source and check its content
        if (event.data.includes("Payment Complete")) {
          const transactionId = extractTransactionId(event.data);

          try {
            const paymentObj = await updatePaymentAfterCheckout(
              user._id,
              user.token,
              paymentId,
              transactionId,
              billingId
            );
            if (paymentObj.message === "Payment updated") {
              successfulToast(
                `Payment Complete. Transaction ID: ${transactionId}`
              );
              successfulToast(
                `Receipt has been emailed to you. Check your spam folder`
              );

              navigate(`/invoice/${paymentObj.data._id}`);
            } else {
              errorToast(paymentObj.message || "Something went wrong");
            }
          } catch (error) {
            errorToast(error.message);
          }
        } else if (event.data.includes("Payment Failed")) {
          const transactionId = extractTransactionId(event.data);

          errorToast(`Payment Failed. Transaction ID: ${transactionId}`);
        }
        setIsLoading(false);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [navigate, paymentId, user._id, user.token, billingId]);

  if (!isOpen) return null;

  const handleSubmit = async (cardData) => {
    let cardErrors = validateCardDetails(cardData);
    if (cardErrors.length > 0) {
      cardErrors.forEach((error) => {
        errorToast(error);
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await checkoutPayment(
        cardData,
        billingDetails,
        amount,
        user._id
      );

      if (response.RedirectData) {
        setIframeContent(response.RedirectData);
        setBillingId(response.billingDetailsId);
      } else {
        errorToast(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      errorToast(error.message);
    }
  };

  return (
    <>
      <div className="c-modal-overlay">
        <div className="c-modal">
          {isLoading ? (
            <>
              <div className="c-modal-header processing">
                <h5>Processing</h5>
                <button onClick={onClose} className="close-button">
                  &times;
                </button>
              </div>
              <iframe
                title="pt"
                srcDoc={iframeContent}
                style={{
                  width: "100%",
                  height: "300px",
                  border: "none",
                  padding: "20px",
                }}
              ></iframe>
              <SmallLoadingSpinner />
            </>
          ) : (
            <>
              <CreditCardForm closeModal={onClose} onSubmit={handleSubmit} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CreditCardModal;
