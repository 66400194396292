const initialState = {
  userDetails: {
    data: {},
    isLoading: true,
    error: null,
  },
  payments: {
    data: {},
    isLoading: true,
    error: null,
  },
};

function userDetailsReducer(state, action) {
  switch (action.type) {
    case "FETCH_USER_START":
      return {
        ...state,
        userDetails: { ...state.userDetails, isLoading: true },
      };
    case "FETCH_USER_SUCCESS":
      return {
        ...state,
        userDetails: { isLoading: false, data: action.payload, error: null },
      };
    case "FETCH_USER_ERROR":
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: false,
          error: action.payload,
        },
      };
    case "FETCH_PAYMENTS_START":
      return { ...state, payments: { ...state.payments, isLoading: true } };
    case "FETCH_PAYMENTS_SUCCESS":
      return {
        ...state,
        payments: { isLoading: false, data: action.payload, error: null },
      };
    case "FETCH_PAYMENTS_ERROR":
      return {
        ...state,
        payments: {
          ...state.payments,
          isLoading: false,
          error: action.payload,
        },
      };
    case "ADD_USER_PAYMENT":
      return {
        ...state,
        payments: {
          ...state.payments,
          data: {
            ...state.payments.data,
            data: [...state.payments.data.data, action.payload],
          },
        },
      };
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: { ...state.userDetails, data: action.payload },
      };
    default:
      return state;
  }
}

export { initialState, userDetailsReducer };
