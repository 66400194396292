import { getOneUser } from "../helpers/usersApi";
import { adminFindUserPayments } from "../helpers/paymentApi";

export const fetchUserDetails = async (dispatch, user, userId) => {
  dispatch({ type: "FETCH_USER_START" });
  try {
    const response = await getOneUser(user, userId);
    dispatch({ type: "FETCH_USER_SUCCESS", payload: response });
  } catch (error) {
    dispatch({ type: "FETCH_USER_ERROR", payload: error.message });
  }
};

export const fetchUserPayments = async (
  dispatch,
  userId,
  token,
  pageNumber
) => {
  dispatch({ type: "FETCH_PAYMENTS_START" });
  try {
    const response = await adminFindUserPayments(userId, token, pageNumber);
    dispatch({ type: "FETCH_PAYMENTS_SUCCESS", payload: response });
  } catch (error) {
    // console.error(error.message);
    dispatch({ type: "FETCH_PAYMENTS_ERROR", payload: error.message });
  }
};

export const addUserPayment = (dispatch, payment) => {
  dispatch({ type: "ADD_USER_PAYMENT", payload: payment });
};

export const setUserDetails = (dispatch, userDetails) => {
  dispatch({ type: "SET_USER_DETAILS", payload: userDetails });
};
