import "./LoadingSpinner.css";

const LargeLoadingSpinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <div
      style={{
        border: "5px solid #f3f3f3",
        borderTop: "5px solid #3b71ca",
        borderRadius: "50%",
        width: "300px",
        height: "300px",
        animation: "spin 1s linear infinite",
      }}
    ></div>
  </div>
);

export default LargeLoadingSpinner;
