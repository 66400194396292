import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBIcon,
} from "mdbreact";

const ErrorComponent = ({ errorMessage }) => {
  return (
    <MDBCard className="text-center" style={{ marginTop: "2rem" }}>
      <MDBCardBody>
        <MDBCardTitle>
          <MDBIcon
            icon="exclamation-triangle"
            size="2x"
            className="text-danger"
          />
          &nbsp;Oops! Something Went Wrong
        </MDBCardTitle>
        <MDBCardText>
          <p>Error: {errorMessage}</p>
          <p>
            Please take a screenshot of this message and email us at
            info@orbtronicsltd.com, or call us at 1-758-486-0469 for assistance.
          </p>
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
};

export default ErrorComponent;
