import axios from "axios";

export const createBillingDetails = async (user, billingDetails) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/billingDetails/${user._id}`,
      billingDetails,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const getAllBillingDetails = async (user) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/billingDetails/${user._id}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const deleteBillingDetails = async (user, billingDetailsId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/billingDetails/${user._id}/${billingDetailsId}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};
