import React, { useState, useContext, useEffect, useReducer } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { UserContext } from "../../context/UserContext";
import { useParams } from "react-router-dom";
import CheckoutDetails from "./CheckoutDetails";
import "./CheckoutPage.css";
import CreditCardModal from "./CreditCardModal";
import BillingDetailsForm from "./BillingDetailsForm";
import BillingDetailsCard from "./BillingDetailsCard";
import { checkoutReducer, initialState } from "../../reducers/checkoutReducer";
import {
  fetchBillingDetails,
  fetchPayment,
  setSelectedBillingDetails,
} from "../../actions/checkoutActions";
import ErrorComponent from "../ErrorComponent";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import SmallLoadingSpinner from "../LoadingSpinners/SmallLoadingSpinner";

const CheckoutPage = () => {
  const { user } = useContext(UserContext);

  const [showBillingDetailsForm, setShowBillingDetailsForm] = useState(false);

  const { id } = useParams();
  const [state, dispatch] = useReducer(checkoutReducer, initialState);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchPayment(dispatch, user._id, user.token, id);
    fetchBillingDetails(dispatch, user);
  }, [dispatch, user._id, user.token, id, user]);

  const { payment, billingDetails, selectedBillingDetails } = state;

  let selectedBillingDetailsId;
  if (selectedBillingDetails && selectedBillingDetails._id) {
    selectedBillingDetailsId = selectedBillingDetails._id;
  }

  if (payment.error) return <ErrorComponent errorMessage={payment.error} />;
  if (billingDetails.error)
    return <ErrorComponent errorMessage={billingDetails.error} />;

  const handleProceedToPayment = () => {
    toggleModal();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const hideForm = () => {
    setShowBillingDetailsForm(false);
  };

  const handleBillingDetailsSelection = (details) => {
    setSelectedBillingDetails(dispatch, details);
  };

  return (
    <>
      <header className="mb-7 checkout-header">
        <div className="p-5 text-center bg-light">
          <h1 className="mb-0 h3">Checkout</h1>
        </div>
      </header>

      <main className="mb-6">
        <MDBContainer>
          <section>
            <MDBRow gx="5">
              <MDBCol lg="8" mb="4">
                {billingDetails.isLoading ? (
                  <LargeLoadingSpinner />
                ) : (
                  <>
                    {showBillingDetailsForm ? (
                      <BillingDetailsForm
                        user={user}
                        hideForm={hideForm}
                        dispatch={dispatch}
                      />
                    ) : (
                      <div>
                        {billingDetails.length <= 0 ? (
                          <div>
                            <h1>No Billing Details</h1>
                            <MDBBtn
                              onClick={() => setShowBillingDetailsForm(true)}
                            >
                              Add Billing Details
                            </MDBBtn>
                          </div>
                        ) : (
                          <div>
                            <h2 className="mb-4">
                              Please Select A Billing Address
                            </h2>
                            {billingDetails.data.map((details) => {
                              return (
                                <div
                                  key={details._id}
                                  onClick={() =>
                                    handleBillingDetailsSelection(details)
                                  }
                                >
                                  <BillingDetailsCard
                                    billingDetails={details}
                                    billingDetailsId={selectedBillingDetailsId}
                                  />
                                </div>
                              );
                            })}
                            <MDBBtn
                              onClick={() => setShowBillingDetailsForm(true)}
                            >
                              Add New
                            </MDBBtn>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </MDBCol>

              <MDBCol lg="4">
                <section className="shadow-4 p-4 rounded-5 mb-4 checkout-details-card">
                  {payment.isLoading ? (
                    <SmallLoadingSpinner />
                  ) : (
                    <>
                      <CheckoutDetails payment={payment.data} />
                      <MDBBtn
                        className="btn-primary btn-rounded w-100 mt-5"
                        onClick={handleProceedToPayment}
                        disabled={selectedBillingDetailsId ? false : true}
                      >
                        Proceed to Payment
                      </MDBBtn>
                    </>
                  )}
                </section>
              </MDBCol>
            </MDBRow>
          </section>
          <CreditCardModal
            isOpen={isModalOpen}
            onClose={toggleModal}
            billingDetails={selectedBillingDetails}
            amount={payment.data.amount}
            paymentId={id}
            user={user}
          />
        </MDBContainer>
      </main>
    </>
  );
};

export default CheckoutPage;
