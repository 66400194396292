// Layout.js
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./components/SideBarComponents/Sidebar";
import { MDBIcon } from "mdb-react-ui-kit";

const Layout = ({ children }) => {
  const location = useLocation();
  const showSidebar = location.pathname !== "/";
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {isSidebarOpen ? (
        <div className="hamburger-menu" onClick={toggleSidebar}>
          <MDBIcon fas icon="bars" className="bars" />
        </div>
      ) : (
        <div className="hamburger-menu" onClick={toggleSidebar}>
          <MDBIcon fas icon="times" className="bars" />
        </div>
      )}

      {showSidebar && !isSidebarOpen && (
        <Sidebar isSidebarOpen={isSidebarOpen} />
      )}
      <div className={`main-content ${showSidebar ? "" : "full-width"}`}>
        {children}
      </div>
    </>
  );
};

export default Layout;
