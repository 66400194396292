const validateExpiryDate = (date) => {
  // Ensure date format is YYMM and exactly 4 digits
  if (!/^\d{4}$/.test(date)) {
    return false;
  }

  const currentYear = new Date().getFullYear() % 100; // Get last two digits of the current year
  const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-indexed

  const year = parseInt(date.substring(0, 2), 10);
  const month = parseInt(date.substring(2, 4), 10);

  // Check if year and month are valid
  if (year < currentYear || (year === currentYear && month < currentMonth)) {
    return false;
  }

  // Check if month is between 1 and 12
  return month >= 1 && month <= 12;
};

const validateCardNumber = (number) => {
  return number.length === 16 && /^\d+$/.test(number);
};

const validateCVV = (cvv) => {
  return cvv.length === 3 && /^\d+$/.test(cvv);
};

export const validateCardDetails = (cardData) => {
  let errors = [];

  if (!validateCardNumber(cardData.cardNumber)) {
    errors.push("Invalid card number. Must be 16 digits.");
  }

  if (!validateExpiryDate(cardData.expiryDate)) {
    errors.push("Invalid expiry date. Format should be YYMM.");
  }

  if (!validateCVV(cardData.cvv)) {
    errors.push("Invalid CVV. Must be 3 digits.");
  }

  return errors;
};
