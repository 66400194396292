import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { MDBCol, MDBRow } from "mdbreact";

const UserPaymentsTable = ({ payments, handleCreatePayment }) => {
  return (
    <div>
      <MDBRow>
        <MDBCol>
          <h2>User Invoices</h2>
        </MDBCol>
        <MDBCol className="text-md-center">
          <MDBBtn onClick={handleCreatePayment} color="success">
            Create New Invoice
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      <MDBTable>
        <MDBTableHead>
          <tr>
            <th>Title</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Due Date</th>
            <th>Date Paid</th>
            <th>Recurring</th>
            <th>Description</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {payments.map((payment) => (
            <tr key={payment._id}>
              <td>{payment.title}</td>
              <td>${payment.amount.toFixed(2)}</td>
              <td>{payment.status}</td>
              <td>{new Date(payment.due_date).toLocaleDateString()}</td>
              <td>
                {payment.date_paid
                  ? new Date(payment.date_paid).toLocaleDateString()
                  : "N/A"}
              </td>
              <td>{payment.isRecurring ? "Yes" : "No"}</td>
              <td>{payment.description}</td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
};

export default UserPaymentsTable;
