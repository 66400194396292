import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import UserDetailCard from "../AdminUserDetailsComponent/UserDetailCard";
import {
  deleteBillingDetails,
  getAllBillingDetails,
} from "../../helpers/billingDetailsApi";
import BillingDetailsForm from "../CheckoutComponents/BillingDetailsForm";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import BillingDetailsCard from "../CheckoutComponents/BillingDetailsCard";
import ErrorComponent from "../ErrorComponent";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import { errorToast, successfulToast } from "../../helpers/toasties";

const ProfilePage = () => {
  const { user, setUser } = useContext(UserContext);
  const [allbillingDetails, setAllBillingDetails] = useState([]);
  const [selectedBillingDetails, setSelectedBillingDetails] = useState();
  const [showBillingDetailsForm, setShowBillingDetailsForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBillingDetails = async () => {
      try {
        setIsLoading(true);
        const billingDetailsList = await getAllBillingDetails(user);
        if (billingDetailsList.message === "All billing details") {
          setAllBillingDetails(billingDetailsList.data);
        } else {
          setError(billingDetailsList.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingDetails();
  }, [user]);

  const hideForm = () => {
    setShowBillingDetailsForm(false);
  };

  const handleDeleteBillingAddress = async (billingAddress) => {
    if (billingAddress) {
      const response = await deleteBillingDetails(user, billingAddress._id);
      if (response.message === "Billing details deleted successfully") {
        successfulToast("Billing Details Deleted");
        const updatedBillingDetails = allbillingDetails.filter(
          (detail) => detail._id !== billingAddress._id
        );
        // Update the state with the filtered billingDetails array
        setAllBillingDetails(updatedBillingDetails);
        setSelectedBillingDetails();
      } else {
        errorToast("Couuld not delete. Contact support");
      }
    }
  };

  if (error) return <ErrorComponent errorMessage={error} />;

  return (
    <div>
      <h1>Welcome {user.first_name}</h1>
      <UserDetailCard userInfo={user} setUserInfo={setUser} />
      <MDBRow className="mt-4">
        <MDBCol md="6">
          <h2 className="my-3">Billing Details</h2>
        </MDBCol>
        <MDBCol md="6">
          {selectedBillingDetails && (
            <MDBBtn
              color="danger"
              onClick={() => handleDeleteBillingAddress(selectedBillingDetails)}
            >
              Delete
            </MDBBtn>
          )}
        </MDBCol>
      </MDBRow>

      {isLoading ? (
        <LargeLoadingSpinner />
      ) : (
        <>
          {showBillingDetailsForm ? (
            <BillingDetailsForm user={user} hideForm={hideForm} />
          ) : (
            <div>
              {allbillingDetails.length <= 0 ? (
                <div>
                  <h1>No Billing Details</h1>
                  <MDBBtn onClick={() => setShowBillingDetailsForm(true)}>
                    Add Billing Details
                  </MDBBtn>
                </div>
              ) : (
                <div>
                  {allbillingDetails.map((details) => {
                    return (
                      <div
                        key={details._id}
                        onClick={() => setSelectedBillingDetails(details)}
                      >
                        <BillingDetailsCard
                          billingDetails={details}
                          billingDetailsId={selectedBillingDetails?._id}
                        />
                      </div>
                    );
                  })}
                  <MDBBtn onClick={() => setShowBillingDetailsForm(true)}>
                    Add New
                  </MDBBtn>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProfilePage;
