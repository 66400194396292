import axios from "axios";
import { successfulToast, errorToast } from "./toasties";

export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        email: email,
        password: password,
      }
    );
    const responseData = response.data;

    if (responseData.message === "login successful") {
      successfulToast("Login successful");

      return responseData.data;
    } else {
      errorToast("Incorrect Username or Password");
    }
  } catch (error) {
    errorToast("Unexpected error occured " + error.message);
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
      {
        email: email,
      }
    );
    const responseData = response.data;

    if (responseData.message === "Email sent") {
      successfulToast(
        "Reset link sent, check your email. This link will expire within 30 minutes"
      );
    } else {
      errorToast("Could not find user with that email");
    }
  } catch (error) {
    // console.error(error.message);
    errorToast(error.response.data.error.message);
  }
};

export const resetPassword = async (id, token, password) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/reset-password/${id}`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseData = response.data;

    if (responseData.message === "Password Reset") {
      successfulToast("Your password has been changed!");
      return true;
    } else {
      errorToast("An error occured");
    }
  } catch (error) {
    // console.error(error.message);
    errorToast("Unexpected error occured " + error.message);
  }
};

export const getAllPayments = async (id, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/payments/users/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const getOnePayment = async (userId, token, paymentId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/payments/users/${userId}/${paymentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const getAllUsers = async (user, page = 1) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const createOneUser = async (user, userData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/register`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    // console.log(response.data)
    return response.data;
  } catch (error) {
    // console.error(error)
    return error;
  }
};

export const getOneUser = async (user, userId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const updateUser = async (token, userId, userData) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const deleteUser = async (token, userId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.message;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const reportAnIssue = async (user, issueBody) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/report-issue/${user._id}`,
      issueBody,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};
