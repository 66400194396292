import axios from "axios";

export const checkoutPayment = async (
  cardData,
  billingDetails,
  amount,
  userId
) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/checkout`,
      {
        cardData,
        billingDetails,
        amount,
        userId,
      }
    );

    return data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};

export const updatePaymentAfterCheckout = async (
  userId,
  token,
  paymentId,
  transactionId,
  billingId
) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/payments/users-checkout/${userId}/${paymentId}`,
      { transactionId, billingId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};
