import React, { useState } from "react";
import { MDBRow, MDBCol, MDBInput, MDBBtn } from "mdb-react-ui-kit";
import { countries } from "../../helpers/countries"; // Import countries data
import { errorToast, successfulToast } from "../../helpers/toasties";
import { createBillingDetails } from "../../helpers/billingDetailsApi";
import SmallLoadingSpinner from "../LoadingSpinners/SmallLoadingSpinner";
import { addOneBillingDetail } from "../../actions/checkoutActions";

const BillingDetailsForm = ({ user, hideForm, dispatch }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "LC",
    postalCode: "",
    country: "Saint Lucia",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      const selectedCountryCode =
        countries.find((country) => country.cname === value)?.ccode || "";
      setFormData({
        ...formData,
        country: value,
        state: selectedCountryCode,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    // List of required fields
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "addressLine1",
      "city",
      "country",
      "postalCode",
    ];

    // Check if any required field is empty
    for (const field of requiredFields) {
      if (!formData[field] || formData[field].trim() === "") {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (validateForm()) {
      try {
        const data = await createBillingDetails(user, formData);
        if (data.message === "Billing details added successfully") {
          addOneBillingDetail(dispatch, data.data);
          successfulToast(data.message);
          hideForm();
        } else {
          errorToast(data.message || "Something went wrong"); // Handle specific API errors
        }
      } catch (error) {
        console.error(error.message);
        errorToast(error.message || "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // Display error message
      errorToast("Please fill in all fields with a *");
      setIsSubmitting(false);
    }
  };

  return (
    <section>
      <form>
        <h5 className="mb-4">Billing Details</h5>
        <MDBRow mb="4">
          <MDBCol>
            <MDBInput
              label="First name*"
              id="form6Example1"
              type="text"
              value={formData.firstName}
              onChange={handleInputChange}
              name="firstName"
            />
          </MDBCol>
          <MDBCol>
            <MDBInput
              label="Last name*"
              id="form6Example2"
              type="text"
              value={formData.lastName}
              onChange={handleInputChange}
              name="lastName"
            />
          </MDBCol>
        </MDBRow>
        <select
          label="Country*"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          className="form-select my-3"
        >
          {countries.map((country) => (
            <option key={country.cname} value={country.cname}>
              {country.cname}
            </option>
          ))}
        </select>
        <MDBInput
          className="my-3"
          label="Address Line 1*"
          id="form6Example4"
          type="text"
          mb="4"
          value={formData.addressLine1}
          onChange={handleInputChange}
          name="addressLine1"
        />
        <MDBInput
          className="my-3"
          label="Address Line 2"
          id="form6Example4"
          type="text"
          mb="4"
          value={formData.addressLine2}
          onChange={handleInputChange}
          name="addressLine2"
        />
        <MDBInput
          className="my-3"
          label="Town/Area*"
          id="form6ExampleTownCity"
          type="text"
          mb="4"
          value={formData.city}
          onChange={handleInputChange}
          name="city"
        />

        <MDBInput
          className="my-3"
          label="Postcode/ZIP*"
          id="form6ExamplePostcodeZIP"
          type="text"
          mb="4"
          value={formData.postalCode}
          onChange={handleInputChange}
          name="postalCode"
        />
        <MDBInput
          className="my-3"
          label="Email*"
          id="form6Example5"
          type="email"
          mb="4"
          value={formData.email}
          onChange={handleInputChange}
          name="email"
        />
        <MDBInput
          className="my-3"
          label="Phone*"
          id="form6Example6"
          type="text"
          mb="4"
          value={formData.phone}
          onChange={handleInputChange}
          name="phone"
        />

        <MDBBtn
          className="btn-primary btn-rounded w-100 mt-5"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SmallLoadingSpinner /> : "Submit Details"}
        </MDBBtn>

        <MDBBtn
          className="btn-danger btn-rounded w-100 mt-5"
          onClick={hideForm}
        >
          Cancel
        </MDBBtn>
      </form>
    </section>
  );
};

export default BillingDetailsForm;
