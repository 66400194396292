import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle } from "mdbreact";
import React from "react";

const UserInfo = ({ user }) => {
  return (
    <MDBCard className="card-height">
      <MDBCardBody>
        <MDBCardTitle className="font-semi-bold title">User Info</MDBCardTitle>
        <MDBCardText className="body-text">
          {user.first_name} {user.last_name}
        </MDBCardText>
        <MDBCardText className="body-text">{user.email}</MDBCardText>
        <MDBCardText className="body-text">{user.company} </MDBCardText>
        <MDBCardText className="body-text">{user.companyAddress}</MDBCardText>

        {/* ... */}
      </MDBCardBody>
    </MDBCard>
  );
};

export default UserInfo;
