import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import React from "react";

const StatsCard = ({ stat, text }) => {
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle>{text}</MDBCardTitle>
        <MDBCardText>{stat}</MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
};

export default StatsCard;
