import { MDBBadge } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";

const TransactionHistorySingle = ({ payment }) => {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex bg-light bottom-sub-box mb-3 ts-go"
      onClick={() => navigate(`/invoice/${payment._id}`)}
    >
      <div className="me-auto p-2">{payment.title} </div>
      <div className="p-2">${payment.amount} </div>
      <MDBBadge
        color={payment.status === "pending" ? "warning" : "success"}
        light
      >
        {payment.status === "pending" ? "Due" : "Paid"}
      </MDBBadge>
    </div>
  );
};

export default TransactionHistorySingle;
