import React, { useState, useEffect } from "react";
import { MDBInput, MDBBtn, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

const UserEditForm = ({ userData, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    companyAddress: "",
    phone: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        first_name: userData.first_name || "",
        last_name: userData.last_name || "",
        email: userData.email || "",
        company: userData.company || "",
        companyAddress: userData.companyAddress || "",
        phone: userData.phone || "",
      });
    }
  }, [userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol>
              <MDBInput
                label="First Name"
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
              />
            </MDBCol>
            <MDBCol>
              <MDBInput
                label="Last Name"
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
              />
            </MDBCol>
          </MDBRow>
          <MDBInput
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <MDBInput
            label="Company"
            type="text"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            required
          />
          <MDBInput
            label="Company Address"
            type="text"
            name="companyAddress"
            value={formData.companyAddress}
            onChange={handleInputChange}
            required
          />
          <MDBInput
            label="Phone"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
          <MDBBtn type="submit" className="mt-2">
            Update
          </MDBBtn>
          <MDBBtn onClick={onCancel} color="warning" className="mt-2">
            Cancel
          </MDBBtn>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};

export default UserEditForm;
