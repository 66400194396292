import React, { useContext, useEffect, useState } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { UserContext } from "../../context/UserContext";
import { getReports } from "../../helpers/reportsApi";

import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import ErrorComponent from "../ErrorComponent";

import OverduePayments from "./OverduePayments";
import StatsCard from "./StatsCard";

const AdminDashboard = () => {
  const [reportsData, setReportsData] = useState({});
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setIsLoading(true);
        const response = await getReports(user.token);
        setReportsData(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReports();
  }, [user.token]);

  if (isLoading) return <LargeLoadingSpinner />;
  if (error) return <ErrorComponent errorMessage={error} />;

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol md="4">
          <StatsCard stat={reportsData.totalUsers} text="Total User Count" />
        </MDBCol>

        <MDBCol md="4">
          <StatsCard
            stat={`$${reportsData.effectiveMrr}`}
            text="Effective MRR"
          />
        </MDBCol>

        <MDBCol md="4">
          <StatsCard
            stat={`$${reportsData.totalCompletedAmount}`}
            text="Total Revenue for the month"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-4">
        <MDBCol md="4">
          <StatsCard
            stat={reportsData.recurringPaymentsCount}
            text="Total Active Recurring payments"
          />
        </MDBCol>

        <MDBCol md="4">
          <StatsCard
            stat={`$${reportsData.totalCompletedRecurringPaymentsAllTime}`}
            text="Total Recurring Revenue"
          />
        </MDBCol>

        <MDBCol md="4">
          <StatsCard
            stat={`$${reportsData.totalCompletedPaymentsAllTime}`}
            text="Total Payments"
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="mt-4">
        <OverduePayments pendingPayments={reportsData.pendingPayments} />
      </MDBRow>
    </MDBContainer>
  );
};

export default AdminDashboard;
