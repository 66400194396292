import React, { useContext, useEffect, useState } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import logo from "../../assets/logo.png";
import { UserContext } from "../../context/UserContext";
import { findAllPendingPayments } from "../../helpers/paymentApi";

const Sidebar = ({ isSidebarOpen }) => {
  const { setUser, user } = useContext(UserContext);
  const [unpaidInvoices, setUnpaidInvoices] = useState(0);

  useEffect(() => {
    if (!user.is_admin) {
      const fetchPendingPayments = async () => {
        const invoices = await findAllPendingPayments(user, 1);
        if (
          invoices.message ===
          `Payments for ${user.first_name + " " + user.last_name}`
        ) {
          // Filter payments with due_date after today's date
          const today = new Date();

          const filteredPayments = invoices.data.filter((payment) => {
            const paymentDueDate = new Date(payment.due_date);
            return paymentDueDate < today;
          });

          setUnpaidInvoices(filteredPayments.length);
        }
      };

      fetchPendingPayments();
    }
  }, [user]);

  const logout = () => {
    setUser(null);
    localStorage.removeItem("orbtronics-user");
  };

  return (
    <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
      <NavLink to="/" className="logo">
        <img src={logo} alt="logo" className="logo" />
      </NavLink>

      {user && user.is_admin ? (
        <nav className="nav flex-column">
          <NavLink to="/admin-dashboard" className="nav-link">
            <MDBIcon fas icon="home" className="me-2" />
            Dashboard
          </NavLink>
          <NavLink to="/all-users" className="nav-link">
            <MDBIcon fas icon="user" className="me-2" />
            All Users
          </NavLink>
          <NavLink to="/all-invoices" className="nav-link">
            <MDBIcon fas icon="file-invoice-dollar" className="me-2" />
            See Invoices
          </NavLink>

          <NavLink to={"/"} className="nav-link" onClick={logout}>
            <MDBIcon fas icon="sign-out-alt" className="me-2" />
            Logout
          </NavLink>
        </nav>
      ) : (
        <nav className="nav flex-column">
          <NavLink to="/client" className="nav-link">
            <MDBIcon fas icon="home" className="me-2" />
            Dashboard
          </NavLink>
          <NavLink to="/profile" className="nav-link">
            <MDBIcon fas icon="user" className="me-2" />
            Profile
          </NavLink>
          <NavLink to="/invoices" className="nav-link">
            <MDBIcon fas icon="file-invoice-dollar" className="me-2" />
            Invoices <span className="invoice-count">{unpaidInvoices}</span>
          </NavLink>
          <NavLink to="/help-users" className="nav-link">
            <MDBIcon fas icon="question" className="me-2" />
            Help
          </NavLink>

          <NavLink to={"/"} className="nav-link" onClick={logout}>
            <MDBIcon fas icon="sign-out-alt" className="me-2" />
            Logout
          </NavLink>
        </nav>
      )}
    </div>
  );
};

export default Sidebar;
