export const filterPayments = (filters) => {
  let filteredQueryString = "";

  // Apply status filter
  if (filters.status) {
    filteredQueryString += `&status=${filters.status}`;
  }

  // Apply currency filter
  if (filters.currency) {
    filteredQueryString += `&currency=${filters.currency}`;
  }

  // Apply isRecurring filter
  if (filters.isRecurring) {
    const isRecurring = filters.isRecurring === "true";
    filteredQueryString += `&isRecurring=${isRecurring}`;
  }

  // Apply amountRange filter
  if (filters.amountRange) {
    filteredQueryString += `&amountRange=${filters.amountRange}`;
  }

  // Apply sorting filters
  if (filters.dueDateOrder) {
    filteredQueryString += `&sortBy=due_date&sortOrder=${filters.dueDateOrder}`;
  }

  // Apply due date filters
  if (filters.datePaidOrder) {
    filteredQueryString += `&sortBy=date_paid&sortOrder=${filters.datePaidOrder}`;
  }

  //Apply company name filters
  if (filters.company) {
    // console.log(filters.company)
    filteredQueryString += `&company=${encodeURIComponent(filters.company)}`;
  }
  return filteredQueryString;
};
