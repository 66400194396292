import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { getAllUsers } from "../../helpers/usersApi";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import "./AllUsersPage.css";
import UserForm from "./UserForm";

const AllUsersPage = () => {
  const { user } = useContext(UserContext);
  const [allUsers, setAllUsers] = useState([]);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const users = await getAllUsers(user, pageNumber);
      if (users.message === "A list of all the users") {
        setAllUsers(users.data);
        setTotalPages(users.totalPages);
        setError(null);
      } else {
        setError(users.message);
      }
    } catch (error) {
      // console.error(error.message);
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsers(); // eslint-disable-next-line
  }, [user, pageNumber]);

  const pagesArray = Array.from(Array(totalPages).keys());

  const handleAddUser = (newUser) => {
    setAllUsers([...allUsers, newUser]);
    setShowAddUserForm(false);
  };

  if (isLoading) {
    return <LargeLoadingSpinner />;
  }

  if (error) {
    return (
      <div>
        <p>{error}</p>
        <MDBBtn onClick={() => fetchUsers()}>Retry</MDBBtn>
      </div>
    );
  }

  return (
    <div>
      <header>
        <section>
          <div
            className="p-5"
            style={{
              height: "200px",
              background:
                "linear-gradient(to right, hsl(209, 42.2%, 65%), hsl(209, 42.2%, 85%))",
            }}
          ></div>
        </section>
      </header>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12">
            <MDBRow>
              <MDBCol md="6">
                <h1 className="text-center mt-4">All Users</h1>
              </MDBCol>
              <MDBCol md="6" className="text-center text-md-end mt-4">
                <Button
                  radius="sm"
                  color="primary"
                  onClick={() => setShowAddUserForm(!showAddUserForm)}
                >
                  <i className="fas fa-plus me-2"></i>
                  Add New User
                </Button>
              </MDBCol>
            </MDBRow>
            {showAddUserForm ? (
              <div className="add-user-form mt-4">
                <UserForm handleAddUser={handleAddUser} user={user} />
              </div>
            ) : (
              <Table aria-label="Users table">
                <TableHeader>
                  <TableColumn>Name</TableColumn>
                  <TableColumn>Company</TableColumn>
                  <TableColumn>Email</TableColumn>
                  <TableColumn>Role</TableColumn>
                </TableHeader>
                <TableBody>
                  {allUsers.length <= 0 ? (
                    <TableRow>
                      <TableCell colSpan={4}>No Users found.</TableCell>
                    </TableRow>
                  ) : (
                    allUsers.map((user) => (
                      <TableRow
                        key={user._id}
                        className="invoice-row"
                        onClick={() => navigate(`/all-users/${user._id}`)}
                      >
                        <TableCell>
                          {user.first_name} {user.last_name}
                        </TableCell>
                        <TableCell>{user.company}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          {user.is_admin ? "Admin" : "User"}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            )}
          </MDBCol>
        </MDBRow>
        <div className="pagin-btn-container pt-3 flex justify-end gap-3">
          {pagesArray.map((p) => (
            <Button
              radius="sm"
              key={p}
              className={`
                ${pageNumber === p + 1 ? "bg-[#3b71ca]" : "bg-gray-400"}
                shadow-[0_4px_9px_-4px_rgba(59,113,202,1)]
                text-white
                py-2.5
                px-5
                
              `}
              onClick={() => setPageNumber(p + 1)}
            >
              {p + 1}
            </Button>
          ))}
        </div>
      </MDBContainer>
    </div>
  );
};

export default AllUsersPage;
