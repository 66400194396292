import React, { useState } from "react";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";

const NewPaymentForm = ({ onSubmit, onCancel }) => {
  const [newPayment, setNewPayment] = useState({
    amount: "",
    title: "",
    description: "",
    due_date: "",
    isRecurring: false,
    last_payment_due_date: "",
    date_paid: null,
    currency: "US",
    frequency: "",
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    console.log(typeof e.target.value);
    setNewPayment({
      ...newPayment,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(newPayment);
  };

  return (
    <div>
      <h1 className="text-center my-3">Create New Invoice</h1>
      <form onSubmit={handleSubmit}>
        <MDBInput
          label="Amount"
          type="number"
          name="amount"
          onChange={handleChange}
          required
          step="0.01"
        />
        <MDBInput
          label="Title"
          type="text"
          name="title"
          className="mt-3"
          onChange={handleChange}
          required
        />
        <MDBInput
          label="Description"
          type="text"
          name="description"
          onChange={handleChange}
          required
        />
        <MDBInput
          label="Due Date"
          type="date"
          name="due_date"
          onChange={handleChange}
          required
        />

        <select
          className="form-select my-3"
          name="currency"
          value={newPayment.currency}
          onChange={handleChange}
        >
          <option value="US">US Dollar</option>
          <option value="XCD">Eastern Caribbean Dollar (XCD)</option>
        </select>

        <div className="form-check my-3">
          <input
            className="form-check-input"
            type="checkbox"
            name="isRecurring"
            id="isRecurring"
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="isRecurring">
            Is Recurring
          </label>
        </div>

        {newPayment.isRecurring && (
          <MDBInput
            label="Last Payment Due Date"
            type="date"
            name="last_payment_due_date"
            onChange={handleChange}
            required={newPayment.isRecurring}
          />
        )}

        {newPayment.isRecurring && (
          <select
            className="form-select my-3"
            name="frequency"
            value={newPayment.frequency}
            onChange={handleChange}
          >
            <option value="">Please Select Value</option>
            <option value="1y">Every Year</option>
            <option value="3m">Every 3 Months</option>
            <option value="1m">Every Month</option>
            <option value="1w">Every Week</option>
            <option value="1d">Every Day</option>
          </select>
        )}

        <MDBBtn type="submit">Submit</MDBBtn>
        <MDBBtn color="danger" onClick={onCancel}>
          Cancel
        </MDBBtn>
      </form>
    </div>
  );
};

export default NewPaymentForm;
