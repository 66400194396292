import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle } from "mdbreact";
import React from "react";
import { formatDateString } from "../../helpers/time";
import { useNavigate } from "react-router-dom";

const LatestInvoice = ({ payment }) => {
  const navigate = useNavigate();

  const navigateBasedOnStatus = () => {
    if (payment.status === "pending") {
      navigate(`/CheckoutPage/${payment._id}`);
    } else {
      navigate(`/invoice/${payment._id}`);
    }
  };

  return (
    <div onClick={navigateBasedOnStatus}>
      <MDBCard className="latest-invoice card-height">
        <MDBCardBody>
          <MDBCardTitle className="font-semi-bold title l-title">
            Latest Invoice
          </MDBCardTitle>
          {payment.status === "pending" ? (
            <div className="body-text">
              <div className="d-flex bg-light bottom-sub-box mb-3">
                <div className="me-auto p-2">Service</div>
                <div className="p-2">{payment.title} </div>
              </div>
              <div className="d-flex bg-light bottom-sub-box mb-3">
                <div className="me-auto p-2">Paid</div>
                <div className="p-2">
                  {" "}
                  {payment.status === "pending"
                    ? "$0.00"
                    : `$ ${payment.amount}`}{" "}
                </div>
              </div>
              <div className="d-flex bg-light bottom-sub-box mb-3">
                <div className="me-auto p-2">Payment Due</div>
                <div className="p-2">{formatDateString(payment.due_date)} </div>
              </div>
              <div className="d-flex bg-light bottom-sub-box mb-3">
                <div className="me-auto p-2">Type</div>
                <div className="p-2">
                  {payment.isRecurring ? "Recurring" : "One Time"}{" "}
                </div>
              </div>
              <div className="d-flex bg-light bottom-sub-box mb-3">
                <div className="me-auto p-2">Amount to pay</div>
                <div className="p-2">$ {payment.amount} </div>
              </div>
            </div>
          ) : (
            <MDBCardText className="body-text">
              All Current Payments Completed
            </MDBCardText>
          )}

          {/* ... */}
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default LatestInvoice;
