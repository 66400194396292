import axios from "axios";

export const getReports = async (token) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    // console.error(error.message);
    return error;
  }
};
