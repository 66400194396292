import React, { useState } from "react";
import "./CreditCardForm.css"; // Make sure this CSS file is correctly imported
import visaImg from "../../assets/visa.png";
import chipImg from "../../assets/chip.png";

const CreditCardForm = ({ closeModal, onSubmit }) => {
  const [cardInfo, setCardInfo] = useState({
    cardNumber: "",
    month: "",
    year: "",
    cvv: "",
  });

  const formatCardNumber = (cardNumber) => {
    // Pad the card number to ensure it has at least 16 characters, using '#' for padding
    const paddedNumber = cardNumber.padEnd(16, "#");

    // Split the padded number into chunks of 4 characters and join them with a dash
    const formattedNumber = Array.from({ length: 4 }, (_, i) =>
      paddedNumber.slice(i * 4, i * 4 + 4)
    ).join("-");

    return formattedNumber;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardInfo({
      ...cardInfo,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Concatenate month and year for expiryDate
    const submissionData = {
      cardNumber: cardInfo.cardNumber,
      cvv: cardInfo.cvv,
      expiryDate: cardInfo.year + cardInfo.month, // Adjusted to match required format
    };
    // Pass the adjusted data to the parent component's submission handler
    onSubmit(submissionData);
  };

  return (
    <div className="container">
      <div className="card-container">
        <div className="front">
          <div className="image">
            {/* Images should be hosted or imported */}
            <img src={chipImg} alt="Chip" className="chip" />
            <img src={visaImg} alt="Visa" />
          </div>
          <div className="card-number-box">
            {formatCardNumber(cardInfo.cardNumber)}
          </div>
          <div className="flexbox">
            {/* <div className="box">
              <span>card holder</span>
              <div className="card-holder-name">
                {cardInfo.cardHolder || "full name"}
              </div>
            </div> */}
            <div className="box">
              <span>expires</span>
              <div className="expiration">
                <span className="exp-month">{cardInfo.month}</span>/
                <span className="exp-year">{cardInfo.year}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="back">
          <div className="stripe"></div>
          <div className="box">
            <span>cvv</span>
            <div className="cvv-box">{cardInfo.cvv}</div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="inputBox">
          <span>card number</span>
          <input
            type="text"
            maxLength="16"
            name="cardNumber"
            className="card-number-input"
            value={cardInfo.cardNumber}
            onChange={handleInputChange}
          />
        </div>

        <div className="flexbox">
          <div className="inputBox">
            <span>expiration mm</span>
            <select
              name="month"
              className="month-input"
              value={cardInfo.month}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                month
              </option>
              {[...Array(12).keys()].map((i) => (
                <option key={i} value={`${i + 1}`.padStart(2, "0")}>
                  {`${i + 1}`.padStart(2, "0")}
                </option>
              ))}
            </select>
          </div>
          <div className="inputBox">
            <span>expiration yy</span>
            <select
              name="year"
              className="year-input"
              value={cardInfo.year}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                year
              </option>
              {[...Array(10).keys()].map((i) => {
                const year = new Date().getFullYear() + i;
                return (
                  <option key={year} value={year.toString().substr(-2)}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="inputBox">
            <span>cvv</span>
            <input
              type="text"
              maxLength="4"
              name="cvv"
              className="cvv-input"
              value={cardInfo.cvv}
              onChange={handleInputChange}
              onFocus={() =>
                document.querySelector(".card-container").classList.add("flip")
              }
              onBlur={() =>
                document
                  .querySelector(".card-container")
                  .classList.remove("flip")
              }
            />
          </div>
        </div>
        <input type="submit" value="submit" className="submit-btn" />
        <button className="cc-cancel-btn" onClick={closeModal}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default CreditCardForm;
