import React, { useState } from "react";
import "../HomePageComponents/HomePage.css";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import { resetPassword } from "../../helpers/usersApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import SmallLoadingSpinner from "../LoadingSpinners/SmallLoadingSpinner";
import { errorToast } from "../../helpers/toasties";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const navigate = useNavigate();
  const { id, token } = useParams();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isStrongPassword) {
      setPasswordError("Password is not strong enough.");
      return;
    }
    if (password !== confirmPassword)
      return errorToast("Passwords do not match");

    setIsLoading(true);
    const passwordChanged = await resetPassword(id, token, password);
    if (passwordChanged) {
      navigate("/");
    }
    setIsLoading(false);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    validatePassword(value);
    setPassword(value);
  };

  const validatePassword = (password) => {
    let errorMessage = "";
    if (!/[a-z]/.test(password)) {
      errorMessage += "Add at least one lowercase letter. ";
    }
    if (!/[A-Z]/.test(password)) {
      errorMessage += "Add at least one uppercase letter. ";
    }
    if (!/[0-9]/.test(password)) {
      errorMessage += "Include at least one number. ";
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errorMessage += "Include at least one special character (!@#$%^&*). ";
    }
    if (password.length < 8) {
      errorMessage += "Password must be at least 8 characters long.";
    }

    setPasswordError(errorMessage);
    setIsStrongPassword(errorMessage === "");
  };

  return (
    <div className="container-fluid ps-md-0">
      <div className="row g-0">
        <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                  <h3 className="login-heading mb-4">Update Your Password</h3>
                  {/* Sign In Form */}
                  <form onSubmit={onSubmit}>
                    <MDBInput
                      className="mb-4"
                      type="password"
                      id="form1Example1"
                      label="New Password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    <MDBInput
                      className="mb-4"
                      type="password"
                      id="form1Example2"
                      label="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <div>
                      <p className="pw-instructions">
                        Password must contain at least one lowercase letter, one
                        uppercase letter, one number, one special character
                        (!@#$%^&*), and be at least 8 characters long.
                      </p>
                      {passwordError && (
                        <p className="text-danger">{passwordError}</p>
                      )}
                    </div>

                    <MDBBtn type="submit" block>
                      {isloading ? <SmallLoadingSpinner /> : "Send"}
                    </MDBBtn>
                  </form>
                  <div className="mt-5">
                    <Link to="/">Go Back</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
