import React, { useContext, useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";
import { UserContext } from "../../context/UserContext";
import { getAllPayments } from "../../helpers/usersApi";
import "./Client.css";
import UserInfo from "./UserInfo";
import TransactionHistorySingle from "./TransactionHistorySingle";
import LatestInvoice from "./LatestInvoice";
import { Link } from "react-router-dom";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import ErrorComponent from "../ErrorComponent";
import { findAllPendingPayments } from "../../helpers/paymentApi";

const Client = () => {
  const { user } = useContext(UserContext);

  const [payments, setPayments] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);

  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        setIsLoading(true);
        const fetchedPayments = await getAllPayments(user._id, user.token);
        const pendingPaymentsObj = await findAllPendingPayments(user, 1);
        if (
          fetchedPayments.message ===
          `Payments for ${user.first_name + " " + user.last_name}`
        ) {
          setPayments(fetchedPayments.data);
          setPendingPayments(pendingPaymentsObj.data);
        } else {
          setError(fetchedPayments.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayments(); // eslint-disable-next-line
  }, [user._id, user.token]);

  if (isLoading) {
    return <LargeLoadingSpinner />; // Show loading spinner
  }

  if (error) {
    return <ErrorComponent errorMessage={error} />; // Show error message
  }

  const today = new Date();

  const filteredPayments = pendingPayments.filter((payment) => {
    const paymentDueDate = new Date(payment.due_date);
    return paymentDueDate < today;
  });

  let balance = 0;
  let lastPayment;
  if (filteredPayments.length > 0) {
    balance = filteredPayments.reduce((total, payment) => {
      return total + payment.amount;
    }, 0);

    lastPayment = filteredPayments.reduce((earliest, current) => {
      return new Date(current.due_date) < new Date(earliest.due_date)
        ? current
        : earliest;
    });
  }

  return (
    <MDBContainer className="page-content">
      <MDBRow>
        <MDBCol md="6">
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBCardTitle className="font-semi-bold title">
                Welcome {user.first_name},
              </MDBCardTitle>
              <MDBCardText className="font-semibold py-4 px-2 fs-2">
                See your latest invoice below
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6">
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBCardTitle className="font-semi-bold title">
                Amount Due
              </MDBCardTitle>
              <MDBCardText className="font-semibold py-4 px-2 fs-2">
                ${balance}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="4">
          {lastPayment ? (
            <LatestInvoice payment={lastPayment} />
          ) : (
            <MDBCard className="card-height">
              <MDBCardBody>
                <MDBCardTitle className="font-semi-bold title">
                  No Invoice Available
                </MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          )}
        </MDBCol>
        <MDBCol md="4">
          <MDBCard className="card-height">
            <MDBCardBody>
              <MDBCardTitle className="font-semi-bold title">
                Transaction History
              </MDBCardTitle>
              {payments.length > 0 ? (
                <>
                  <div className="body-text">
                    {payments
                      .filter((payment) => payment.status !== "pending") // Filter for completed payments
                      .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
                      .slice(0, 4) // Take the top 4 payments
                      .map((payment) => {
                        return (
                          <TransactionHistorySingle
                            payment={payment}
                            key={payment._id}
                          />
                        );
                      })}
                  </div>
                  <div className="text-center see-more-box">
                    <Link className="see-more" to="/invoices">
                      See More
                    </Link>
                  </div>
                </>
              ) : (
                <MDBCardText>No Recent Transactions</MDBCardText>
              )}

              {/* ... */}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="4">
          <UserInfo user={user} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Client;
