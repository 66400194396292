import React from "react";
import { Route, Routes } from "react-router-dom";

import Invoice from "./components/InvoiceComponents/Invoice";
import Client from "./components/ClientComponents/Client";
import InvoiceList from "./components/InvoiceListComponents/InvoiceList";
import CheckoutPage from "./components/CheckoutComponents/CheckoutPage";
import RequireAuth from "./components/RequireAuth";
import AllUsersPage from "./components/AdminAllUsersComponents/AllUsersPage";
import UserDetailsPage from "./components/AdminUserDetailsComponent/UserDetailsPage";
import AllPaymentsPage from "./components/AdminInvoicesComponents/AllPaymentsPage";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import ProfilePage from "./components/ProfilePageComponents/ProfilePage";
import RequireAdmin from "./components/RequireAdmin";
import NotFoundPage from "./components/NotFoundPage";
import UsersHelpPage from "./components/UsersHelp/UsersHelpPage";

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/invoice/:id"
        element={
          <RequireAuth>
            {" "}
            <Invoice />
          </RequireAuth>
        }
      />
      <Route
        path="/client"
        element={
          <RequireAuth>
            <Client />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices"
        element={
          <RequireAuth>
            {" "}
            <InvoiceList />
          </RequireAuth>
        }
      />
      <Route
        path="/CheckoutPage/:id"
        element={
          <RequireAuth>
            {" "}
            <CheckoutPage />
          </RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <ProfilePage />
          </RequireAuth>
        }
      />
      <Route
        path="/help-users"
        element={
          <RequireAuth>
            <UsersHelpPage />
          </RequireAuth>
        }
      />
      <Route
        path="/admin-dashboard"
        element={
          <RequireAdmin>
            <AdminDashboard />
          </RequireAdmin>
        }
      />
      <Route
        path="/all-users"
        element={
          <RequireAdmin>
            <AllUsersPage />
          </RequireAdmin>
        }
      />
      <Route
        path="/all-users/:id"
        element={
          <RequireAdmin>
            <UserDetailsPage />
          </RequireAdmin>
        }
      />
      <Route
        path="/all-invoices"
        element={
          <RequireAdmin>
            <AllPaymentsPage />
          </RequireAdmin>
        }
      />
      <Route path="*" element={<NotFoundPage />} />{" "}
      {/* This will match any route not defined above */}
    </Routes>
  );
};

export default AppRouter;
