import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";

const RequireAdmin = (props) => {
  const { user } = useContext(UserContext);

  if (!user) return <Navigate to="/" />;

  if (!user.is_admin) return <Navigate to="/client" />;

  return props.children;
};

export default RequireAdmin;
