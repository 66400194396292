import React, { useContext, useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { MDBBtn } from "mdb-react-ui-kit";
import { findAllPayments } from "../../helpers/paymentApi";
import { UserContext } from "../../context/UserContext";
import { formatDateString } from "../../helpers/time";
import "./InvoiceList.css";
import { useNavigate } from "react-router-dom";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import ErrorComponent from "../ErrorComponent";

const InvoiceList = () => {
  const [payments, setPayments] = useState([]);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const invoices = await findAllPayments(user, pageNumber);
        if (
          invoices.message ===
          `Payments for ${user.first_name + " " + user.last_name}`
        ) {
          setPayments(invoices.data);
          setTotalPages(invoices.totalPages);
        } else {
          setError(invoices.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPayments();
  }, [user, pageNumber]);

  const pagesArray = Array.from(Array(totalPages).keys());

  if (isLoading) return <LargeLoadingSpinner />;
  if (error) return <ErrorComponent errorMessage={error} />;

  return (
    <>
      <header className="invoice-list-header">
        <section>
          <div
            className="p-5"
            style={{
              height: "200px",
              background:
                "linear-gradient(to right, hsl(209, 42.2%, 65%), hsl(209, 42.2%, 85%))",
            }}
          ></div>
        </section>
      </header>

      <MDBContainer>
        <MDBRow>
          <MDBCol md="12">
            <h1 className="text-center mt-4">Invoices</h1>
            <MDBTable>
              <MDBTableHead color="light">
                <tr>
                  <th className="order-header">Order #</th>
                  <th>Service</th>
                  <th>Payment Amount</th>
                  <th>Date Paid</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {/* Table rows here */}
                {payments.length <= 0 ? (
                  <tr>
                    <td colSpan="4">
                      No invoices found. Please check back later or contact
                      support.
                    </td>
                  </tr>
                ) : (
                  <>
                    {payments.map((payment) => {
                      return (
                        <tr
                          key={payment._id}
                          className="invoice-row"
                          onClick={() => navigate(`/invoice/${payment._id}`)}
                        >
                          <td className="order-col">{payment._id} </td>
                          <td>{payment.title} </td>
                          <td>${payment.amount} </td>
                          <td>
                            {payment.date_paid
                              ? formatDateString(payment.date_paid)
                              : "No Payment Made"}{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </MDBTableBody>
            </MDBTable>
            <div className="pagin-btn-container">
              {pagesArray.map((p) => (
                <MDBBtn
                  key={p}
                  className={
                    pageNumber === p + 1 ? "pagin-btn active" : "pagin-btn"
                  }
                  onClick={() => setPageNumber(p + 1)}
                >
                  {p + 1}
                </MDBBtn>
              ))}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default InvoiceList;
