import React, { useState, useEffect } from "react";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";

import {
  Button,
  Skeleton,
  Select,
  SelectItem,
  Table,
  TableColumn,
  TableCell,
  TableHeader,
  TableBody,
  TableRow,
  Input,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
const AllPayments = ({
  payments,
  companies,
  handleFilterChange,
  totalPages,
  currencies,
  pageNumber,
  setPageNumber,
}) => {
  const [filters, setFilters] = useState({
    status: "",
    dueDateOrder: "",
    company: "",
    currency: "",
    amountRange: "",
    isRecurring: "",
    lastPaymentDateOrder: "",
    datePaidOrder: "",
  });
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    handleFilterChange(filters); // eslint-disable-next-line
  }, [filters]);

  const updateFilter = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };


  const searchByCompany = (e) => {
    const searchValue = e.target.value;
    setSearchInput(searchValue);
    if (searchValue === "") {
      updateFilter("company", "");
    } else {
      const matchingCompany = companies.find((company) =>
        company.company.toLowerCase().includes(searchValue.toLowerCase())
      );
      updateFilter("company", matchingCompany ? matchingCompany._id : "");
    }
  };






  const navigate = useNavigate();

  const resetFilters = () => {
    setFilters({
      status: "",
      dueDateOrder: "",
      company: "",
      currency: "",
      amountRange: "",
      isRecurring: "",
      lastPaymentDateOrder: "",
      datePaidOrder: "",
    });
  };

  const pagesArray = Array.from(Array(totalPages).keys());

  const renderDropdown = (label, field, options, currentValue) => (
    <div className="form-group">
      <style jsx>{`
        ul {
          padding-left: 0;
        }
      `}
      </style>

      <Select
        labelPlacement="outside"
        label={label}
        aria-label={label}
        onChange={(value) => updateFilter(field, value.target.value)}
        value={currentValue}
        selectedKeys={currentValue ? [currentValue] : []}
        className="render-dropdown"
      >
        <SelectItem key="" value="">Choose</SelectItem>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </Select>
    </div>
  );

  return (
    <div>
      <MDBRow>
        <MDBCol>
          <h2>All Invoices</h2>
          <form
            onSubmit={(e) => e.preventDefault()}
            className="mb-3transition duration-500"
          >
            <Input
              radius="sm"
              className="nextui-input"
              placeholder="Search for companies"
              aria-label="Search for companies"
              autoComplete="on"
              value={searchInput}
              onChange={searchByCompany}
              startContent={
                <MDBIcon fas icon="search" className="me-2 cursor-pointer" />
              }
            />
          </form>
        </MDBCol>

        <MDBCol className="flex justify-end">
          <Button color="primary" onClick={resetFilters}>
            Reset Filters
          </Button>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          {renderDropdown(
            "Filter by Status",
            "status",
            [
              { value: "pending", label: "Pending" },
              { value: "refunded", label: "Refunded" },
              { value: "complete", label: "Complete" },
            ],
            filters.status
          )}
        </MDBCol>
        <MDBCol>
          {renderDropdown(
            "Sort by Due Date",
            "dueDateOrder",
            [
              { value: "asc", label: "Ascending" },
              { value: "desc", label: "Descending" },
            ],
            filters.dueDateOrder
          )}
        </MDBCol>

        <MDBCol>
          {renderDropdown(
            "Filter by Currency",
            "currency",
            currencies.map((currency) => ({
              value: currency,
              label: currency,
            })),
            filters.currency
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          {renderDropdown(
            "Filter by Recurring",
            "isRecurring",
            [
              { value: "true", label: "Is Recurring" },
              { value: "false", label: "One Time Payment" },
            ],
            filters.isRecurring
          )}
        </MDBCol>

        <MDBCol>
          {renderDropdown(
            "Sort by Date Paid",
            "datePaidOrder",
            [
              { value: "asc", label: "Ascending" },
              { value: "desc", label: "Descending" },
            ],
            filters.datePaidOrder
          )}
        </MDBCol>
        <MDBCol>
          {renderDropdown(
            "Filter by Amount",
            "amountRange",
            [
              { value: "lt100", label: "< 100" },
              { value: "100to500", label: "100 - 500" },
              { value: "500to1000", label: "500 - 1000" },
              { value: "gt1000", label: "> 1000" },
            ],
            filters.amountRange
          )}
        </MDBCol>

        <MDBCol>
          {renderDropdown(
            "Filter by Company Name",
            "company",
            companies.map((company) => ({
              value: company._id,
              label: company.company,
            })),
            filters.company
          )}
        </MDBCol>
      </MDBRow>
      {payments.length === 0 ? (
        <Table>
          <TableHeader>
            <TableColumn>Company</TableColumn>
            <TableColumn>Amount</TableColumn>
            <TableColumn>Status</TableColumn>
            <TableColumn>Due Date</TableColumn>
            <TableColumn>Date Paid</TableColumn>
            <TableColumn>Recurring</TableColumn>
            <TableColumn>Description</TableColumn>
          </TableHeader>
          <TableBody>
            {[...Array(5)].map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton className="h-3 w-3/4 rounded-full" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-3 w-1/2 rounded-full" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-3 w-1/4 rounded-full" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-3 w-1/2 rounded-full" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-3 w-1/2 rounded-full" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-3 w-1/4 rounded-full" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-3 w-3/4 rounded-full" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table>
          <TableHeader>
            <TableColumn>Company</TableColumn>
            <TableColumn>Amount</TableColumn>
            <TableColumn>Status</TableColumn>
            <TableColumn>Due Date</TableColumn>
            <TableColumn>Date Paid</TableColumn>
            <TableColumn>Recurring</TableColumn>
            <TableColumn>Description</TableColumn>
          </TableHeader>
          <TableBody>
            {payments.map((payment) => (
              <TableRow
                key={payment._id}
                onClick={() => navigate(`/invoice/${payment._id}`)}
                className="invoice-row"
              >
                <TableCell>{payment.user_id?.company}</TableCell>
                <TableCell>${payment.amount.toFixed(2)}</TableCell>
                <TableCell>{payment.status}</TableCell>
                <TableCell>
                  {new Date(payment.due_date).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {payment.date_paid
                    ? new Date(payment.date_paid).toLocaleDateString()
                    : "N/A"}
                </TableCell>
                <TableCell>{payment.isRecurring ? "Yes" : "No"}</TableCell>
                <TableCell>{payment.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <div className="mt-2 flex gap-3 justify-end pagin-btn-container">
        {pagesArray.map((p) => (
          <Button
            color="primary"
            className={pageNumber === p + 1 ? "pagin-btn active" : "pagin-btn"}
            onClick={() => setPageNumber(p + 1)}
            key={p}
          >
            {p + 1}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default AllPayments;
