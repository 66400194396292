const initialState = {
  payment: {
    data: {},
    isLoading: true,
    error: null,
  },
  billingDetails: {
    data: [],
    isLoading: true,
    error: null,
  },
  selectedBillingDetails: null,
};

function checkoutReducer(state, action) {
  switch (action.type) {
    case "FETCH_PAYMENT_START":
      return { ...state, payment: { ...state.payment, isLoading: true } };
    case "FETCH_PAYMENT_SUCCESS":
      return {
        ...state,
        payment: { ...state.payment, isLoading: false, data: action.payload },
      };
    case "FETCH_PAYMENT_ERROR":
      return {
        ...state,
        payment: { ...state.payment, isLoading: false, error: action.payload },
      };
    case "FETCH_BILLING_DETAILS_START":
      return {
        ...state,
        billingDetails: { ...state.billingDetails, isLoading: true },
      };
    case "FETCH_BILLING_DETAILS_SUCCESS":
      return {
        ...state,
        billingDetails: {
          ...state.billingDetails,
          isLoading: false,
          data: action.payload,
        },
      };
    case "FETCH_BILLING_DETAILS_ERROR":
      return {
        ...state,
        billingDetails: {
          ...state.billingDetails,
          isLoading: false,
          error: action.payload,
        },
      };
    case "ADD_ONE_BILLING_DETAIL":
      return {
        ...state,
        billingDetails: {
          ...state.billingDetails,
          isLoading: false,
          data: [...state.billingDetails.data, action.payload],
        },
      };
    case "SET_SELECTED_BILLING_DETAILS":
      return { ...state, selectedBillingDetails: action.payload };
    default:
      return state;
  }
}

export { initialState, checkoutReducer };
